body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: gainsboro;
}

.candidateContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  max-width: 1000px;
}

.candidateInfo {
  text-align: center;
  min-width: 175px;
  margin: 5px;
  padding: 3px;
  font-size: small;
}

.candidateInfo:hover {
  background-color: lightskyblue;
  cursor: pointer;
}

.candidateInfo ul {
  list-style-type: none;
  margin: 3px;
  padding: 0px;
}

.authorInfo {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
  width: 90%;
}

.authorMetrics {
  min-width: 250px;
  margin: 3px;
  padding: 3px;
}

.metricsTable{
  border-collapse: separate;
  border-spacing: 15px 0px;
}

.authorPapers {
  flex: 1;
  min-width: 300px;
  margin: 3px;
  padding: 3px;
  font-size: small;
}

.paper {
  display: flex;
  justify-content: space-between;
  margin: 10px 5px 10px 5px;
}

.paper .info {
  flex: 1;
}

.paper .citations {
  min-width: 80px;
  margin-left: 15px;
}

.paper:hover {
  cursor: pointer;
}

.paper h5{
  margin: 0 0 2px 0;
  font-size: medium;
}

.paper p{
  margin: 0;
}

.navigation {
  display: flex;
  justify-content: center;
}

.authorName:hover {
  cursor: pointer;
}